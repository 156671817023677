import React from 'react';

import { DataScroller } from 'primereact/datascroller';

import { Typography, GroupSelector } from '../Util';


export default class BillLineItems extends React.Component {
    constructor(props) {
        super(props);
        this.itemTemplate = this.itemTemplate.bind(this)
    }

    itemTemplate(lineItem) {
        for (var item of lineItem.prediction) {
            item.label = item.group_name + " (" + item.precision.toFixed(4) + ")" 
        }

        return (
            <div className="col-12">
                <div className="flex flex-column xl:flex-row xl:align-items-start p-4 gap-4">
                    <Typography sixe="xl" color="900">{lineItem.line_item}</Typography>
                    <GroupSelector 
                        defaultValue={lineItem.prediction[0]}
                        options={lineItem.prediction}
                        optionLabel="label"
                        placeholder="Line Item Group"
                        className="w-full md:w-14rem"
                        checkmark={true} 
                        highlightOnSelect={false}
                    />
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="card">
                <DataScroller 
                    value={this.props.predictionData}
                    itemTemplate={this.itemTemplate}
                    rows={5}
                    inline
                    scrollHeight="500px"
                    header="Line Items Predictions" />
            </div>
        )
    }
}
