import { CoreBox } from "./Core"


export class SimpleFlexBox extends CoreBox {
    constructor(props) {
        super(props)

        const reverse = this.props.reverse ? "-reverse" : ""

        this.coreClasses = "flex"
        this.coreClasses += this.props.noWrap ? "" : " flex-wrap"
        this.coreClasses += this.props.column ? ` flex-column${reverse}` : ` flex-row${reverse}`;
        this.coreClasses += this.props.alignItems ? ` align-items-${this.props.alignItems}` : "";
        this.coreClasses += this.props.gap ? ` gap-${this.props.gap}` : ""
        this.coreClasses += this.props.rowGap ? ` row-gap-${this.props.rowGap}` : ""
        this.coreClasses += this.props.columnGap ? ` column-gap-${this.props.columnGap}` : "";
        this.coreClasses += this.props.mainAxis ? ` justify-content-${this.props.mainAxis}` : ""
        this.coreClasses += this.props.crossAxis ? ` align-content-${this.props.crossAxis}` : ""
    }

    render() {
        return (
            <div 
                className={`${this.coreClasses} ${this.className}`}
                style={this.style}
            >
                {this.props.children}
            </div>
        )
    }
}

export class LeftOrderedFlexBox extends SimpleFlexBox {
    constructor(props) {
        super(props)
        this.coreClasses += " justify-content-start"
    }
}

export class HorizontallyCenteredFlexBox extends SimpleFlexBox {
    constructor(props) {
        super(props)
        this.coreClasses += " justify-content-center"
    }
}

export class RightOrderedFlexBox extends SimpleFlexBox {
    constructor(props) {
        super(props)
        this.coreClasses += " justify-content-end"
    }
}

export class TopOrderedFlexBox extends SimpleFlexBox {
    constructor(props) {
        super(props)
        this.coreClasses += " align-content-start"
    }
}

export class VerticallyCenteredFlexBox extends SimpleFlexBox {
    constructor(props) {
        super(props)
        this.coreClasses += " align-content-center"
    }
}

export class BottomOrderedFlexBox extends SimpleFlexBox {
    constructor(props) {
        super(props)
        this.coreClasses += " align-content-end"
    }
}

export class LeftTopOrderedFlexBox extends SimpleFlexBox {
    constructor(props) {
        super(props)
        this.coreClasses += " justify-content-start align-content-start"
    }
}

export class CenterTopOrderedFlexBox extends SimpleFlexBox {
    constructor(props) {
        super(props)
        this.coreClasses += " justify-content-center align-content-start"
    }
}

export class RightTopOrderedFlexBox extends SimpleFlexBox {
    constructor(props) {
        super(props)
        this.coreClasses += " justify-content-end align-content-start"
    }
}

export class LeftCenterOrderedFlexBox extends SimpleFlexBox {
    constructor(props) {
        super(props)
        this.coreClasses += " justify-content-start align-content-center"
    }
}

export class CenteredFlexBox extends SimpleFlexBox {
    constructor(props) {
        super(props)
        this.coreClasses += " justify-content-center align-content-center"
    }
}

export class RightCenterOrderedFlexBox extends SimpleFlexBox {
    constructor(props) {
        super(props)
        this.coreClasses += " justify-content-end align-content-center"
    }
}

export class LeftBottomOrderedFlexBox extends SimpleFlexBox {
    constructor(props) {
        super(props)
        this.coreClasses += " justify-content-start align-content-end"
    }
}

export class CenterBottomOrderedFlexBox extends SimpleFlexBox {
    constructor(props) {
        super(props)
        this.coreClasses += " justify-content-center align-content-end"
    }
}

export class RightBottomOrderedFlexBox extends SimpleFlexBox {
    constructor(props) {
        super(props)
        this.coreClasses += " justify-content-end align-content-end"
    }
}
