import React, { useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { Tag } from 'primereact/tag';

import { CoreBox } from '../layout/Core'


export default function DragAndDropBill(props) {
    const toast = useRef(null);
    const [totalSize, setTotalSize] = useState(0);
    const fileUploadRef = useRef(null);
    const MAX_FILE_SIZE = 5000000
    const BYTES2MB = 1000000
    
    const onTemplateSelect = (e) => {
        let _totalSize = totalSize;
        let files = e.files;

        Object.keys(files).forEach((key) => {
            _totalSize += files[key].size || 0;
        });

        setTotalSize(_totalSize);
        props.hidePredictionData()
    };

    const onTemplateUpload = (e) => {
        let _totalSize = 0;

        e.files.forEach((file) => {
            _totalSize += file.size || 0;
        });

        setTotalSize(_totalSize);
        props.showPredictionData(JSON.parse(e.xhr.responseText))
        props.updateFilePath(e.files[0])

        toast.current.show({ severity: 'info', summary: 'Success', detail: 'Bill uploaded' });
    };

    const onTemplateRemove = (file, callback) => {
        setTotalSize(totalSize - file.size);
        callback();
    };

    const onTemplateClear = () => {
        setTotalSize(0);
    };

    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        const value = totalSize / (MAX_FILE_SIZE / 100);
        const formatedValue = fileUploadRef && fileUploadRef.current ? 
            fileUploadRef.current.formatSize(totalSize) : '0 B';

        return (
            <CoreBox 
                className={className}
                style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}
            >
                {chooseButton}
                {uploadButton}
                {cancelButton}
                <CoreBox className="flex align-items-center gap-3 ml-auto">
                    <span>{formatedValue} / {MAX_FILE_SIZE / BYTES2MB} MB</span>
                    <ProgressBar
                        value={value}
                        showValue={false}
                        style={{ width: '10rem', height: '12px' }}
                    />
                </CoreBox>
            </CoreBox>
        );
    };

    const itemTemplate = (file, props) => {
        return (
            <CoreBox className="flex align-items-center flex-wrap">
                <CoreBox className="flex align-items-center" style={{ width: '40%' }}>
                    <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
                    <span className="flex flex-column text-left ml-3">
                        {file.name}
                        <small>{new Date().toLocaleDateString()}</small>
                    </span>
                </CoreBox>
                <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
                <Button
                    type="button"
                    icon="pi pi-times"
                    className="p-button-outlined p-button-rounded p-button-danger ml-auto"
                    onClick={() => onTemplateRemove(file, props.onRemove)}
                />
            </CoreBox>
        );
    };

    const emptyTemplate = () => {
        return (
            <CoreBox className="flex align-items-center flex-column">
                <i 
                    className="pi pi-image mt-3 p-5"
                    style={
                        { 
                            fontSize: '5em',
                            borderRadius: '50%',
                            backgroundColor: 'var(--surface-b)',
                            color: 'var(--surface-d)'
                        }
                    }
                />
                <span 
                    style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }}
                    className="my-5"
                >
                    Drag and Drop Bill Here
                </span>
            </CoreBox>
        );
    };

    const chooseOptions = { 
        icon: 'pi pi-fw pi-images',
        iconOnly: true,
        className: 'custom-choose-btn p-button-rounded p-button-outlined' 
    };
    const uploadOptions = { 
        icon: 'pi pi-fw pi-cloud-upload', 
        iconOnly: true, 
        className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined' 
    };
    const cancelOptions = { 
        icon: 'pi pi-fw pi-times', 
        iconOnly: true, 
        className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' 
    };

    return (
        <CoreBox>
            <Toast ref={toast}></Toast>

            <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
            <Tooltip target=".custom-upload-btn" content="Upload" position="bottom" />
            <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />

            <FileUpload 
                ref={fileUploadRef} 
                name="bill" 
                url={process.env.REACT_APP_ACCTIUS_ML_URL + "/ocr/bill"} 
                accept="image/*" 
                maxFileSize={MAX_FILE_SIZE}
                onUpload={onTemplateUpload} 
                onSelect={onTemplateSelect} 
                onError={onTemplateClear} 
                onClear={onTemplateClear}
                headerTemplate={headerTemplate} 
                itemTemplate={itemTemplate} 
                emptyTemplate={emptyTemplate}
                chooseOptions={chooseOptions} 
                uploadOptions={uploadOptions} 
                cancelOptions={cancelOptions}
            />
        </CoreBox>
    )
}
