import React from 'react';

import DragAndDropBill from '../ocr/DragAndDropBill';
import BillDataTree from '../ocr/BillDataTree'

import { CoreBox } from '../layout/Core'
import { HorizontallyCenteredFlexBox }  from '../layout/FlexBoxes'
import BillLineItems from '../prediction/BillLineItems';


export default class BillOCR extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            showPrediction: false,
            file: null,
            data: null,
            groupPredictionData: []
        }

        this.collectLineItems = this.collectLineItems.bind(this)
        this.showPredictionData = this.showPredictionData.bind(this)
        this.hidePredictionData = this.hidePredictionData.bind(this)
        this.updateFilePath = this.updateFilePath.bind(this) 
    }

    collectLineItems(data) {
        const lineItems = data.find(el => el.key === "line_items");
        let out = new Set()

        if(lineItems && lineItems.children) {
            for (const lineItem of lineItems.children) {
                if(lineItem.children) {
                    const lineItemName = lineItem.children.find(el => el.label === "Name");
                    if(lineItemName && lineItemName.children && lineItemName.children.length > 0) {
                        if(lineItemName.children[0] && lineItemName.children[0].label) {
                            out.add(lineItemName.children[0].label)
                        }
                    }
                }
            }
        }
        return [...out];
    }

    showPredictionData(data) {
        this.setState({showPrediction: true})
        this.setState({data: data})

        fetch(process.env.REACT_APP_ACCTIUS_ML_URL + "/prediction/line_items", {
                method: 'POST',
                headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
                },
                body: JSON.stringify({line_items: this.collectLineItems(data)})
            })
            .then(response => response.json())
            .then(json => this.setState({groupPredictionData: json.predictions}))
            .catch(error => console.error(error));
    }

    updateFilePath(file) {
        this.setState({file: file})
    }

    hidePredictionData() {
        this.setState({showPrediction: false})
    }

    render() {
        return (
            <CoreBox>
                <CoreBox className='m-2'>
                    <DragAndDropBill
                        showPredictionData={this.showPredictionData}
                        hidePredictionData={this.hidePredictionData}
                        updateFilePath={this.updateFilePath}
                    />
                </CoreBox>
                
                { this.state.showPrediction ? 
                    <HorizontallyCenteredFlexBox
                        noWrap
                        className="card m-2"
                        style={{height: "55vh"}}
                    >
                        <BillDataTree nodes={this.state.data} />
                        <img alt={this.state.file.name} src={this.state.file.objectURL} />
                        <BillLineItems predictionData={this.state.groupPredictionData}/>
                    </HorizontallyCenteredFlexBox> : null 
                }
            </CoreBox>
        )
    }
}
