import React from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';


export default class BillLineItemsAnnotation extends React.Component {
    constructor(props) {
        super(props)

        this.emptyLineItem = {
            id: null,
            name: '',
            account: {},
        };

        this.accounts = [
            { name: 'Assets', code: '1000' },
            { name: 'Liabilities', code: '2000' },
            { name: 'Equity', code: '3000' },
            { name: 'Revenue', code: '4000' },
            { name: 'Expenses', code: '5000' }
        ];

        this.toast = React.createRef();
        this.dt = React.createRef();
        
        // generate line items model
        const lineItems = [];
        for (const lineItem of props.lineItems) {
            lineItems.push(
                {
                    id: this.createId(),
                    name: lineItem,
                    account: {}
                }
            )
        }

        this.state = {
            lineItems: lineItems,
            lineItemDialog: false,
            deleteLineItemDialog: false,
            activeLineItem: this.emptyLineItem,
            selectedLineItems: null,
            submitted: false
        }

        // bind methods
        this.createId = this.createId.bind(this)
        this.createNewLineItem = this.createNewLineItem.bind(this)
        this.hideLineItemDialog = this.hideLineItemDialog.bind(this)
        this.hideDeleteLineItemDialog = this.hideDeleteLineItemDialog.bind(this)
        this.addNewLineItem = this.addNewLineItem.bind(this) 
        this.editLineItem = this.editLineItem.bind(this)
        this.confirmDeleteLineItem = this.confirmDeleteLineItem.bind(this)
        this.deleteLineItem = this.deleteLineItem.bind(this)
        this.findIndexById = this.findIndexById.bind(this)
        this.saveLineItemsAccounts = this.saveLineItemsAccounts.bind(this)
        this.onLineItemNameChange = this.onLineItemNameChange.bind(this)
        this.onAccountChange = this.onAccountChange.bind(this)
        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this)
        this.rightToolbarTemplate = this.rightToolbarTemplate.bind(this)
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this)

        // templates
        this.lineItemDialogFooter = (
            <React.Fragment>
                <Button 
                    label="Cancel" 
                    icon="pi pi-times" 
                    outlined 
                    onClick={this.hideLineItemDialog}
                />
                <Button 
                    label="Save" 
                    icon="pi pi-check" 
                    onClick={this.addNewLineItem} 
                />
            </React.Fragment>
        );
        this.deleteLineItemDialogFooter = (
            <React.Fragment>
                <Button 
                    label="No"
                    icon="pi pi-times"
                    outlined
                    onClick={this.hideDeleteLineItemDialog} 
                />
                <Button 
                    label="Yes" 
                    icon="pi pi-check" 
                    severity="danger" 
                    onClick={this.deleteLineItem} 
                />
            </React.Fragment>
        );
        this.paginator = "PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown";
    }

    createId() {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    }

    createNewLineItem() {
        this.setState({activeLineItem: this.emptyLineItem})
        this.setState({submitted: false});
        this.setState({lineItemDialog: true});
    }

    hideLineItemDialog() {
        this.setState({submitted: false});
        this.setState({lineItemDialog: false});
    }

    hideDeleteLineItemDialog() {
        this.setState({deleteLineItemDialog: false});
    }

    addNewLineItem() {
        this.setState({submitted: true});

        if (this.state.activeLineItem.name.trim()) {
            let _lineItems = [...this.state.lineItems];
            let _activeLineItem = { ...this.state.activeLineItem };

            if (this.state.activeLineItem.id) {
                const index = this.findIndexById(this.state.activeLineItem.id);

                _lineItems[index] = _activeLineItem;
                this.toast.current.show(
                    {
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'Line item updated',
                        life: 3000
                    }
                );
            } else {
                _activeLineItem.id = this.createId();
                _lineItems.push(_activeLineItem);
                this.toast.current.show(
                    { 
                        severity: 'success', 
                        summary: 'Successful', 
                        detail: 'Line item created', 
                        life: 3000 
                    }
                );
            }

            this.setState({lineItems: _lineItems})
            this.setState({lineItemDialog: false})
            this.setState({activeLineItem: this.emptyLineItem})
        }
    }

    editLineItem(lineItem) {
        this.setState({activeLineItem: { ...lineItem }});
        this.setState({lineItemDialog: true})
    }

    confirmDeleteLineItem(lineItem) {
        this.setState({activeLineItem: lineItem});
        this.setState({deleteLineItemDialog: true});
    }

    deleteLineItem() {
        let _lineItems = this.state.lineItems.filter((val) => val.id !== this.state.activeLineItem.id);

        this.setState({lineItems: _lineItems});
        this.setState({deleteLineItemDialog: false});
        this.setState({activeLineItem: this.emptyLineItem})

        this.toast.current.show(
            { 
                severity: 'success',
                summary: 'Successful',
                detail: 'Line item deleted',
                life: 3000 
            }
        );
    }

    findIndexById(id) {
        let index = -1;

        for (let i = 0; i < this.state.lineItems.length; i++) {
            if (this.state.lineItems[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    saveLineItemsAccounts() {
        this.props.hide()
    }

    onLineItemNameChange(e) {
        const val = (e.target && e.target.value) || '';
        let _lineItem = { ...this.state.activeLineItem };

        _lineItem["name"] = val;
        this.setState({activeLineItem: _lineItem})
    }

    onAccountChange(e) {
        const val = (e.target && e.target.value) || '';
        let _lineItem = { ...this.state.activeLineItem };

        _lineItem["account"] = val;
        this.setState({activeLineItem: _lineItem})
    }

    leftToolbarTemplate() {
        return (
            <div className="flex flex-wrap gap-2">
                <Button 
                    label="New"
                    icon="pi pi-plus"
                    severity="success"
                    onClick={this.createNewLineItem}
                />
            </div>
        )
    }

    rightToolbarTemplate() {
        return <Button label="Save" icon="pi pi-save" className="p-button-help" onClick={this.saveLineItemsAccounts} />;
    }

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button 
                    icon="pi pi-pencil"
                    rounded
                    outlined
                    className="mr-2"
                    onClick={() => this.editLineItem(rowData)} 
                />
                <Button 
                    icon="pi pi-trash"
                    rounded
                    outlined
                    severity="danger"
                    onClick={() => this.confirmDeleteLineItem(rowData)} 
                />
            </React.Fragment>
        );
    }

    render() {
        return (
            <div>
                <Toast ref={this.toast} />
                <div className="card">
                    <Toolbar 
                        className="mb-4" 
                        left={this.leftToolbarTemplate} 
                        right={this.rightToolbarTemplate}
                    />

                    <DataTable 
                        ref={this.dt} 
                        value={this.state.lineItems} 
                        selection={this.state.selectedLineItems} 
                        onSelectionChange={(e) => this.setState({selectedLineItems: e.value})}
                        dataKey="id"  
                        paginator 
                        rows={5} 
                        rowsPerPageOptions={[5, 10]}
                        paginatorTemplate={this.paginator}
                        currentPageReportTemplate="{first} - {last} / {totalRecords}"
                    >
                        <Column 
                            selectionMode="multiple"
                            exportable={false}
                        />
                        <Column 
                            field="name"
                            header="Line item"
                            sortable
                            style={{ minWidth: '12rem' }}
                        />
                        <Column 
                            field="account.name"
                            header="Account"
                            sortable
                            style={{ minWidth: '16rem' }}
                        />
                        <Column 
                            body={this.actionBodyTemplate}
                            exportable={false}
                            style={{ minWidth: '12rem' }}
                        />
                    </DataTable>
                </div>

                <Dialog 
                    visible={this.state.lineItemDialog}
                    style={{ width: '32rem' }}
                    breakpoints={{ '960px': '75vw', '641px': '90vw' }}
                    header="Line item editor"
                    modal
                    className="p-fluid"
                    footer={this.lineItemDialogFooter}
                    onHide={this.hideLineItemDialog}
                >
                    <div className="field">
                        <label htmlFor="lineItem" className="font-bold">
                            Line item
                        </label>
                        <InputText 
                            id="lineItem"
                            value={this.state.activeLineItem.name}
                            onChange={(e) => this.onLineItemNameChange(e)}
                            required
                            autoFocus
                            className={
                                classNames(
                                    {'p-invalid': this.state.submitted && !this.state.activeLineItem.name}
                                )
                            }
                        />
                        {this.state.submitted && !this.state.activeLineItem.name && (
                            <small className="p-error">
                                Line item is required.
                            </small>
                        )}
                    </div>
                    <div className="field">
                        <label htmlFor="account" className="font-bold">
                            Account
                        </label>
                        <Dropdown 
                            value={this.state.activeLineItem.account}
                            onChange={(e) => this.onAccountChange(e)}
                            options={this.accounts} 
                            optionLabel="name" 
                            placeholder="Select account" 
                            className="w-full md:w-14rem" 
                        />
                    </div>
                </Dialog>

                <Dialog 
                    visible={this.state.deleteLineItemDialog}
                    style={{ width: '32rem' }}
                    breakpoints={{ '960px': '75vw', '641px': '90vw' }}
                    header="Confirm"
                    modal
                    footer={this.deleteLineItemDialogFooter}
                    onHide={this.hideDeleteLineItemDialog}
                >
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                        {this.state.activeLineItem && (
                            <span>
                                Are you sure you want to delete <b>{this.state.activeLineItem.name}</b>?
                            </span>
                        )}
                    </div>
                </Dialog>
            </div>
        )
    }
}
