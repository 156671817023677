// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
    font-size: 14px;
}

body {
    margin: 0;
    padding: 0;
    height: 100vh;
	max-height: 100vh;
    box-sizing: border-box;
    overflow-x: hidden;
    font-family: var(--font-family);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
`, "",{"version":3,"sources":["webpack://./src/css/app.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,SAAS;IACT,UAAU;IACV,aAAa;CAChB,iBAAiB;IACd,sBAAsB;IACtB,kBAAkB;IAClB,+BAA+B;IAC/B,mCAAmC;IACnC,kCAAkC;AACtC","sourcesContent":["html {\n    font-size: 14px;\n}\n\nbody {\n    margin: 0;\n    padding: 0;\n    height: 100vh;\n\tmax-height: 100vh;\n    box-sizing: border-box;\n    overflow-x: hidden;\n    font-family: var(--font-family);\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
