import React from 'react';

import { Tree } from 'primereact/tree';
import { Button } from 'primereact/button';

import { SimpleFlexBox } from '../layout/FlexBoxes'


export default class BillDataTree extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            expandedKeys: null
        }

        this.expandAll = this.expandAll.bind(this)
        this.collapseAll = this.collapseAll.bind(this)
        this.expandNode = this.expandNode.bind(this)
    }

    expandAll() {
        let _expandedKeys = {};

        for (let node of this.props.nodes) {
            this.expandNode(node, _expandedKeys);
        }

        this.setState({expandedKeys: _expandedKeys});
    }

    collapseAll() {
        this.setState({expandedKeys: {}});
    }

    expandNode(node, keys) {
        if (node.children && node.children.length) {
            keys[node.key] = true;

            for (let child of node.children) {
                this.expandNode(child, keys);
            }
        }
    }

    render() {
        return (
            <SimpleFlexBox noWrap column alignItems="center" className="card mr-2">
                <SimpleFlexBox className="flex flex-wrap gap-2 mb-4">
                    <Button 
                        type="button" 
                        icon="pi pi-plus" 
                        label="Expand all" 
                        onClick={this.expandAll} 
                    />
                    <Button 
                        type="button" 
                        icon="pi pi-minus"
                        label="Collapse all"
                        onClick={this.collapseAll}
                    />
                </SimpleFlexBox>
    
                <Tree 
                    value={this.props.nodes}
                    expandedKeys={this.state.expandedKeys} 
                    onToggle={(e) => this.setState({expandedKeys: e.value})}
                    className="w-full md:w-30rem"
                    style={{overflowY: "scroll"}}/>
            </SimpleFlexBox>
        )
    }
}
