import React from 'react';
import { Route, Routes } from "react-router-dom";

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-teal/theme.css';
import 'primereact/resources/primereact.min.css'
import 'primeflex/primeflex.min.css'
import './css/app.css';

import BillOCR from './components/views/BillOCR'
import BillAnnotation from './components/views/BillAnnotation'


export default class App extends React.Component {
    render() {
        return (
            <Routes>
                <Route exact path="/" element={<BillOCR/>} />
                <Route exact path="/labeling" element={<BillAnnotation/>} />
            </Routes> 
        )
    }
}
