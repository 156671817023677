import React from "react";
import { useParams } from 'react-router-dom';

import { Dropdown } from 'primereact/dropdown';

import { Core } from "./layout/Core";


export class FloatingLabelWrapper extends Core {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <span className="p-float-label">
                {this.props.children}
                <label htmlFor={this.props.elementId}>{this.props.label}</label>
            </span>
        )
    }
}

export class Icon extends Core {
    constructor(props) {
        super(props)

        this.coreClasses = "pi"
        this.coreClasses += this.props.spin ? " pi-spin" : ""
        this.coreClasses += this.props.name ? ` pi-${this.props.name}` : ""
    }

    render() {
        return (
            <i
                className={`${this.coreClasses} ${this.className}`}
                style={this.style}
            />
        )
    }
}

export class Typography extends Core {
    constructor(props) {
        super(props)

        this.coreClasses = this.props.size ? `text-${this.props.size}` : ""
        this.coreClasses += this.props.font ? ` font-${this.props.font}` : ""
        this.coreClasses += this.props.color ? ` text-${this.props.color}` : ""
    }

    render() {
        return (
            <p
                className={`${this.coreClasses} ${this.className}`}
                style={this.style}
            >
                {this.props.children}
            </p>
        )
    }
}

export class GroupSelector extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value: props.defaultValue
        }
    }

    render() {
        return( 
            <Dropdown 
                value={this.state.value}
                onChange={(e) => this.setState({value: e.value})}
                options={this.props.options}
                optionLabel={this.props.optionLabel}
                placeholder={this.props.placeholder}
                className={this.props.className}
                checkmark={this.props.checkmark}
                highlightOnSelect={this.props.highlightOnSelect}
            />
        );
    }
}

export function withRouteParams(Component) {
    return props => <Component {...props} routeParams={useParams()} />;
}
