import React from 'react';


export class Core extends React.Component {
    constructor(props) {
        super(props)
        this.className = this.props.className ? this.props.className : ""
        this.style = this.props.style ? this.props.style : null
    }
}

export class CoreBox extends Core {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div 
                className={this.className}
                style={this.style}
            >
                {this.props.children}
            </div>
        )
    }
}
