import React from 'react';
import { Toast } from 'primereact/toast';

import DragAndDropBill from '../ocr/DragAndDropBill';
import BillLineItemsAnnotation from '../annotation/BillLineItemsAnnotation';

import { CoreBox } from '../layout/Core'
import { HorizontallyCenteredFlexBox }  from '../layout/FlexBoxes'


export default class BillAnnotation extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            showAnnotation: false,
            file: null,
            data: null,
            lineItems: [],
            groupPredictionData: []
        }

        this.toast = React.createRef();

        this.collectLineItems = this.collectLineItems.bind(this)
        this.showAnnotationData = this.showAnnotationData.bind(this)
        this.hideAnnotationData = this.hideAnnotationData.bind(this)
        this.updateFilePath = this.updateFilePath.bind(this)
        this.hideAfterSaveAnnotationData = this.hideAfterSaveAnnotationData.bind(this)
    }

    collectLineItems(data) {
        const lineItems = data.find(el => el.key === "line_items");
        let out = new Set()

        if(lineItems && lineItems.children) {
            for (const lineItem of lineItems.children) {
                if(lineItem.children) {
                    const lineItemName = lineItem.children.find(el => el.label === "Name");
                    if(lineItemName && lineItemName.children && lineItemName.children.length > 0) {
                        if(lineItemName.children[0] && lineItemName.children[0].label) {
                            out.add(lineItemName.children[0].label)
                        }
                    }
                }
            }
        }
        return [...out];
    }

    showAnnotationData(data) {
        this.setState({showAnnotation: true})
        this.setState({data: data})
        this.setState({lineItems: this.collectLineItems(data)})
    }

    updateFilePath(file) {
        this.setState({file: file})
    }

    hideAnnotationData() {
        this.setState({showAnnotation: false})
    }

    hideAfterSaveAnnotationData() {
        this.toast.current.show(
            { 
                severity: 'success',
                summary: 'Successful',
                detail: 'Annotated line items saved',
                life: 3000
            }
        );
        this.setState({showAnnotation: false})
    }

    render() {
        return (
            <CoreBox>
                <Toast ref={this.toast} />
                <CoreBox className='m-2'>
                    <DragAndDropBill
                        showPredictionData={this.showAnnotationData}
                        hidePredictionData={this.hideAnnotationData}
                        updateFilePath={this.updateFilePath}
                    />
                </CoreBox>
                
                { this.state.showAnnotation ? 
                    <HorizontallyCenteredFlexBox
                        noWrap
                        className="card m-2"
                        style={{height: "55vh"}}
                    >
                        <BillLineItemsAnnotation 
                            lineItems={this.state.lineItems} 
                            hide={this.hideAfterSaveAnnotationData}
                        />
                        <img alt={this.state.file.name} src={this.state.file.objectURL} />
                    </HorizontallyCenteredFlexBox> : null 
                }
            </CoreBox>
        )
    }
}
